import { $path } from 'remix-routes';

interface IOnboardMarketingResolver {
  url: string;
  step?: number;
}

export const onboardMarketingResolver = (activeRoute: string): IOnboardMarketingResolver => {
  const data = {
    [$path('/onboarding/closed-beta').toString()]: {
      url: 'sign_up_success.png',
      step: 4,
    },
    [$path('/onboarding/email-verification').toString()]: {
      url: 'sign_up_email_verification.png',
      step: 2,
    },
    default: {
      url: 'sign_up_choice.png',
    },
  };

  return data[activeRoute] || data['default'];
};
